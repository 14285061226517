import styled from 'styled-components';
import { black, darkGreen, lightGray, matterhornText } from '../../styles/Variables';
import { deviceMax } from '../../styles/MediaQuery';
import { Subtitle } from '../Contact/ContactStyle';
import { CommonInput } from '../../styles/Common';
import { Title } from '../ContactUk/ContactUkStyle';

export const GermanTitle = styled(Title)`
    margin-top: 2px;
    margin-bottom: 2rem;
`;

export const GermanSubtitle = styled(Subtitle)`
    margin-top: 40px;
`;

export const Description = styled.p`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color: ${black};
    font-weight: 400;
    margin: 0;

    a {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1rem;
        color: ${darkGreen};
        font-weight: 400;
        text-decoration: none;
    }
`;

export const SearchFormsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
`;

export const SearchForm = styled.form`
    width: 50%;
    min-width: 310px;
    box-sizing: border-box;
    padding-right: 20px;

    @media ${deviceMax.tablet} {
        width: 100%;
    }
`;

export const ButtonWrapper = styled.div`
    margin-top: 1rem;
    width: 200px;
`;

export const ButtonPlzWrapper = styled(ButtonWrapper)`
    @media ${deviceMax.tablet} {
        width: 100%;
    }
`;

export const PersonWrapper = styled.div`
    width: 100%;
`;

export const Service = styled.div`
    display: flex;
    width: 100%;
    border-top: 1px solid ${lightGray};
    border-bottom: 1px solid ${lightGray};
    margin-bottom: 60px;

    * {
        font-size: 0.875rem;
        color: ${matterhornText};
        font-family: Arial, Helvetica, sans-serif;
    }

    @media ${deviceMax.tablet} {
        flex-direction: column;
        border-top: unset;
    }
`;

interface ServiceTitleInterface {
    readonly fullWidth?: boolean;
}

export const ServiceTitle = styled.div<ServiceTitleInterface>`
    width: ${(props) => (props.fullWidth ? '33%' : 'calc(33% - 140px)')};
    margin: 12px 0;
    padding-left: 20px;
    font-weight: ${(props) => (props.fullWidth ? '400' : '700')};
    display: flex;
    flex-direction: column;

    span {
        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    @media ${deviceMax.tablet} {
        padding-left: 0;
    }
`;

export const ServicePhone = styled.div`
    width: 33%;
    margin: 12px 0;
    padding-left: 20px;
    display: flex;
    flex-direction: column;

    span {
        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    a {
        color: ${darkGreen};
        text-decoration: none;
    }

    @media ${deviceMax.tablet} {
        padding-left: 0;
    }
`;

export const ServiceMail = styled.div`
    width: 33%;
    margin: 12px 0;
    padding-left: 20px;
    display: flex;
    flex-direction: column;

    span {
        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    a {
        color: ${darkGreen};
        text-decoration: none;
    }

    @media ${deviceMax.tablet} {
        padding-left: 0;
    }
`;

export const LinkWrapper = styled.div`
    margin: 18px 0px;
`;

export const SearchInput = styled(CommonInput)`
    height: ${(props) => (props.height ? props.height : '1.9rem')};
    padding-top: 0.4rem;

    @media ${deviceMax.tablet} {
        width: calc(100% - 6px);
    }
`;
