import React, { FC } from 'react';
import { PersonContainer, PersonBox, CloseButtton, CloseIcon, PersonBoxTitle } from '../../Contact/ContactStyle';
import { PersonWrapper, Service, ServiceTitle, ServicePhone, ServiceMail } from '../ContactGermanStyle';
import ContactPerson from '../../Contact/ContactPerson/ContactPerson';
import { ContactCityPersonInterface } from '../../../interfaces/contactZipResponse';

interface PersonByCityInterface {
    isPersonBoxCityVisible: boolean;
    setIsPersonBoxCityVisible: Function;
    dataToContactPersonByCity: ContactCityPersonInterface;
}

const PersonByCity: FC<PersonByCityInterface> = ({
    isPersonBoxCityVisible,
    setIsPersonBoxCityVisible,
    dataToContactPersonByCity,
}) => (
    <PersonContainer isVisible={isPersonBoxCityVisible}>
        <PersonBox>
            <CloseButtton onClick={() => setIsPersonBoxCityVisible(false)}>
                <CloseIcon />
            </CloseButtton>
            <PersonWrapper>
                <PersonBoxTitle>
                    IHRE BOGE-KONTAKTPERSON FÜR DIE REGION {dataToContactPersonByCity?.city?.toLocaleLowerCase()}
                </PersonBoxTitle>
                {dataToContactPersonByCity && (
                    <ContactPerson
                        contactPersonData={{
                            attachments: { admPhoto0: dataToContactPersonByCity.attachments?.admPhoto0 },
                            Name: dataToContactPersonByCity.admName,
                            Email: dataToContactPersonByCity.admMail,
                            Fon: dataToContactPersonByCity.admTel,
                        }}
                    />
                )}
            </PersonWrapper>
            <PersonBoxTitle>IHR HÄNDLER IN {dataToContactPersonByCity?.city?.toLocaleLowerCase()}</PersonBoxTitle>
            {dataToContactPersonByCity && (
                <Service>
                    <ServiceTitle fullWidth={true}>
                        <span>{dataToContactPersonByCity.name}</span>
                        <span>{dataToContactPersonByCity.street}</span>
                        <span>
                            {dataToContactPersonByCity.zip} {dataToContactPersonByCity.city}
                        </span>
                    </ServiceTitle>
                    <ServicePhone>
                        <span>
                            Tel.:{' '}
                            <a href={`tel:${dataToContactPersonByCity.phone}`}>{dataToContactPersonByCity.phone}</a>
                        </span>
                        <span>
                            Fax: <a href={`tel:${dataToContactPersonByCity.fax}`}>{dataToContactPersonByCity.fax}</a>
                        </span>
                    </ServicePhone>
                    <ServiceMail>
                        <span>
                            Web: <a href={dataToContactPersonByCity.www}>{dataToContactPersonByCity.www}</a>
                        </span>
                    </ServiceMail>
                </Service>
            )}
        </PersonBox>
    </PersonContainer>
);

export default PersonByCity;
