import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import ContactGerman from '../components/ContactGerman/ContactGerman';
import Contact from '../components/Contact/Contact';
import Layout from '../components/Layout/Layout';
import Header from '../components/HeaderDynamicPages/Header';
import { staticPageRedirection } from '../utils/staticPagesRedirection';
import { CONTACT_DE_LANGUAGES } from '../constants/sitesLanguagesGroup';
import { ContactPartnerExample } from '../templateObjects/internationalContact';
import Alternate from '../components/Seo/Alternate';
import { prepareAlternateData } from '../utils/prepareAlternateData';
import contactTranslations from '../JSON/StaticSitesTranslations/contactTranslations.json';
import { getSiteName } from '../utils/getSiteName';
import { DE } from '../constants/languageCode';

const ContactGermanPage = ({
    data: {
        drupal: {
            contact_partners: { data: partnersData },
        },
    },
}) => {
    const { language } = useI18next();
    const { t } = useTranslation();
    const languagesWithUrls = [];
    process.env.LANGUAGES.split(',').forEach((language: string) =>
        languagesWithUrls.push(prepareAlternateData(language, 'contact'))
    );

    useEffect(() => {
        staticPageRedirection(language, 'contact', CONTACT_DE_LANGUAGES);
    }, []);

    return language === DE ? (
        <Layout title={`${getSiteName(language)} | ${t('contact')}`} languageVersionsUrl={contactTranslations}>
            <Alternate languages={languagesWithUrls} />
            <Header item={t('contact')} />
            <ContactGerman />
        </Layout>
    ) : (
        <>
            <Alternate languages={languagesWithUrls} />
            <Contact contactPartner={partnersData !== null ? JSON.parse(partnersData) : ContactPartnerExample} />
        </>
    );
};

export default ContactGermanPage;

export const query = graphql`
    query ($language: String!) {
        drupal {
            contact_partners {
                data
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
