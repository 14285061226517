import axios from 'axios';

export const useFetchCities = async (): Promise<any> => {
    const data = await axios.post(
        `${process.env.DRUPAL_URL}/graphql`,
        {
            query: `query fetchCities {
                        contact_cities {
                            data
                        }
                    }
            `,
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
    const result = JSON.parse(data?.data?.data?.contact_cities?.data);
    return result ?? {};
};
