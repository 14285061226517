import React, { useEffect, useState, FC } from 'react';
import { SearchForm, ButtonWrapper } from '../ContactGermanStyle';
import { FormTitle } from '../../Contact/ContactStyle';
import { SendButtonGreyGradient } from '../../../styles/Common';
import SelectWithSearch from '../../Inputs/SelectWithSearch/SelectWithSearch';
import { useFetchRegionDetails } from '../../../hooks/useFetchRegionDetails';
import { useFetchCities } from '../../../hooks/useFetchCities';

interface ContactSearchByCityInterface {
    city: string;
    setCity: Function;
    setIsPersonBoxCityVisible: Function;
    setIsPersonBoxZipVisible: Function;
    setDataToContactPersonByCity: Function;
}

interface DataToSelectInterface {
    value: string;
    name: string;
}

const ContactSearchByCity: FC<ContactSearchByCityInterface> = ({
    city,
    setCity,
    setIsPersonBoxCityVisible,
    setIsPersonBoxZipVisible,
    setDataToContactPersonByCity,
}) => {
    const [cities, setCities] = useState([]);
    const [dataToCitiesSelect, setDataToCitiesSelect] = useState([]);

    const getCities = async (): Promise<void> => {
        const citiesData = await useFetchCities();
        setCities(citiesData.items);
    };

    useEffect(() => {
        getCities();
    }, []);

    useEffect(() => {
        if (cities.length > 0) {
            dataToCitiesSelect.length = 0;
            cities
                .filter((city) => city)
                ?.map((city) => {
                    setDataToCitiesSelect((dataToCitiesSelect) => [
                        ...dataToCitiesSelect,
                        convertDataToSelectComponent(city),
                    ]);
                });
        }
    }, [cities]);

    const convertDataToSelectComponent = (data: any): DataToSelectInterface => {
        const dataToSelect = {
            value: data.Stadt,
            name: data.Stadt,
        };
        return dataToSelect;
    };

    const searchPartnerByRegion = async (e: any): Promise<void> => {
        e.preventDefault();
        const data = await useFetchRegionDetails(city);
        setIsPersonBoxCityVisible(true);
        setIsPersonBoxZipVisible(false);
        setDataToContactPersonByCity(data);
    };

    return (
        <SearchForm>
            <FormTitle>BOGE Händler</FormTitle>
            <SelectWithSearch
                setState={setCity}
                currentValue={city}
                selectData={dataToCitiesSelect}
                defaultValue={'Bitte auswählen'}
                width={'100%'}
            />
            <ButtonWrapper>
                <SendButtonGreyGradient width="100%" onClick={searchPartnerByRegion}>
                    Städte-Suche starten
                </SendButtonGreyGradient>
            </ButtonWrapper>
        </SearchForm>
    );
};

export default ContactSearchByCity;
