import {
    ContactZipResponseInterface,
    ContactZipPersonInterface,
    ContactCityPersonInterface,
} from '../interfaces/contactZipResponse';

export const contactZipObjectTemplate: ContactZipResponseInterface = {
    vkorg: '',
    contacts: [
        {
            attachments: {
                Photo0: '',
            },
            Name: '',
            Mail: '',
            Tel: '',
            type: '',
            sectionTitle: '',
        },
    ],
};

export const contactZipPersonTemplate: ContactZipPersonInterface = {
    attachments: {
        Photo0: '',
    },
    Name: '',
    Mail: '',
    Tel: '',
    type: '',
    sectionTitle: '',
};

export const contactCityPersonTemplate: ContactCityPersonInterface = {
    vkg: '',
    city: '',
    name: '',
    street: '',
    zip: '',
    phone: '',
    fax: '',
    email: '',
    www: '',
    cityreal: '',
    admName: '',
    admTel: '',
    attachments: {
        admPhoto0: '',
    },
    admMail: '',
};
