import React, { FC } from 'react';
import { PersonContainer, PersonBox, CloseButtton, CloseIcon, PersonBoxTitle } from '../../Contact/ContactStyle';
import { PersonWrapper, Service, ServiceTitle, ServicePhone, ServiceMail } from '../ContactGermanStyle';
import ContactPerson from '../../Contact/ContactPerson/ContactPerson';
import { ContactZipPersonInterface } from '../../../interfaces/contactZipResponse';

interface PersonByZipInterface {
    isPersonBoxZipVisible: boolean;
    setIsPersonBoxZipVisible: Function;
    contactPersonData: any;
    dataToContactPerson: any;
    service: ContactZipPersonInterface;
}

const PersonByZip: FC<PersonByZipInterface> = ({
    isPersonBoxZipVisible,
    setIsPersonBoxZipVisible,
    contactPersonData,
    dataToContactPerson,
    service,
}) => (
    <PersonContainer isVisible={isPersonBoxZipVisible}>
        <PersonBox>
            <CloseButtton onClick={() => setIsPersonBoxZipVisible(false)}>
                <CloseIcon />
            </CloseButtton>
            {Object.keys(contactPersonData).length > 0 &&
                contactPersonData.contacts?.length > 0 &&
                contactPersonData.contacts
                    ?.filter((contact: ContactZipPersonInterface) => contact && contact.type !== 'service')
                    .map((contact: ContactZipPersonInterface, index: number) => (
                        <PersonWrapper key={contact.Name}>
                            <PersonBoxTitle>{contact.sectionTitle}</PersonBoxTitle>
                            <ContactPerson contactPersonData={dataToContactPerson[index]} />
                        </PersonWrapper>
                    ))}
            {service && service.sectionTitle && (
                <>
                    <PersonBoxTitle>{service.sectionTitle}</PersonBoxTitle>
                    <Service>
                        <ServiceTitle>{service.Name}</ServiceTitle>
                        <ServicePhone>
                            Mobil: <a href={`tel:${service.Tel}`}>{service.Tel}</a>
                        </ServicePhone>
                        <ServiceMail>
                            E-Mail: <a href={`mailto:${service.Mail}`}>{service.Mail}</a>
                        </ServiceMail>
                    </Service>
                </>
            )}
        </PersonBox>
    </PersonContainer>
);

export default PersonByZip;
