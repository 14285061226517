import axios from 'axios';
import { contactZipObjectTemplate } from '../templateObjects/contactZipObject';

export const useFetchZipSearch = async (zip: string): Promise<any> => {
    const data = await axios.post(
        `${process.env.DRUPAL_URL}/graphql`,
        {
            query: `query fetchPartnersByZip($zip: String!) {
                zip_details(zip: $zip) {
                    data
                }
            }`,
            variables: {
                zip: zip,
            },
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
    const result = JSON.parse(data?.data?.data?.zip_details?.data);
    return result ?? contactZipObjectTemplate;
};
