import React, { FC } from 'react';
import { SearchForm, ButtonPlzWrapper, SearchInput } from '../ContactGermanStyle';
import { FormTitle } from '../../Contact/ContactStyle';
import { SendButtonGreyGradient } from '../../../styles/Common';
import { useFetchZipSearch } from '../../../hooks/useFetchZipSearch';
import { ContactZipPersonInterface } from '../../../interfaces/contactZipResponse';

interface ContactSearchByZipInterface {
    zip: string;
    setZip: Function;
    previousZip: string;
    isPersonBoxZipVisible: boolean;
    setService: Function;
    setContactPersonData: Function;
    setIsPersonBoxZipVisible: Function;
    setIsPersonBoxCityVisible: Function;
    setPreviousZip: Function;
}

const ContactSearchByZip: FC<ContactSearchByZipInterface> = ({
    zip,
    setZip,
    previousZip,
    isPersonBoxZipVisible,
    setService,
    setContactPersonData,
    setIsPersonBoxZipVisible,
    setIsPersonBoxCityVisible,
    setPreviousZip,
}) => {
    const searchPartnerByZip = async (e: any): Promise<void> => {
        e.preventDefault();
        if (zip !== previousZip || !isPersonBoxZipVisible) {
            const data = await useFetchZipSearch(zip + '');
            const serviceArray =
                Object.keys(data).length > 0 &&
                data.contacts?.length > 0 &&
                data.contacts?.filter((contact: ContactZipPersonInterface) => contact && contact.type === 'service');
            serviceArray?.length > 0 && setService(serviceArray[0]);
            setContactPersonData(data);
            setIsPersonBoxZipVisible(true);
            setIsPersonBoxCityVisible(false);
            setPreviousZip(zip);
        }
    };

    const searchPartnerByZipByEnter = (e: any): void => {
        if (e.key === 'Enter' && zip.length > 0) {
            e.preventDefault();
            searchPartnerByZip(e);
        }
    };

    return (
        <SearchForm>
            <FormTitle>BOGE Direkt</FormTitle>
            <SearchInput placeholder="Ihre Postleitzahl" type="text" onChange={(e) => setZip(e.target.value)} />
            <ButtonPlzWrapper>
                <SendButtonGreyGradient width="100%" onClick={searchPartnerByZip} onKeyDown={searchPartnerByZipByEnter}>
                    PLZ-Suche starten
                </SendButtonGreyGradient>
            </ButtonPlzWrapper>
        </SearchForm>
    );
};

export default ContactSearchByZip;
