import React, { useState, useEffect } from 'react';
import { Container } from '../Contact/ContactStyle';
import { GermanTitle, GermanSubtitle, Description, SearchFormsContainer, LinkWrapper } from './ContactGermanStyle';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ButtonWithLinkGreyGradient } from '../../styles/Common';
import {
    ContactZipResponseInterface,
    ContactZipPersonInterface,
    ContactCityPersonInterface,
} from '../../interfaces/contactZipResponse';
import { ContactPersonInterface } from '../../interfaces/contactPerson';
import {
    contactZipObjectTemplate,
    contactZipPersonTemplate,
    contactCityPersonTemplate,
} from '../../templateObjects/contactZipObject';
import ContactData from '../Contact/ContactData/ContactData';
import ContactSearchByZip from './ContactSearchByZip/ContactSearchByZip';
import ContactSearchByCity from './ContactSearchByCity/ContactSearchByCity';
import PersonByCity from './PersonByCity/PersonByCity';
import PersonByZip from './PersonByZip/PersonByZip';
import WebForm from '../WebForm/WebForm';
import { CONTACT_DE, CONTACT_DE_TITLE } from '../../constants/webformsName';
import { DE } from '../../constants/languageCode';
import { greyBackground } from '../../styles/Variables';

const ContactGerman = () => {
    const { t } = useTranslation();
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [previousZip, setPreviousZip] = useState('');
    const [contactPersonData, setContactPersonData] = useState<ContactZipResponseInterface>(contactZipObjectTemplate);
    const [dataToContactPerson, setDataToContactPerson] = useState([]);
    const [dataToContactPersonByCity, setDataToContactPersonByCity] =
        useState<ContactCityPersonInterface>(contactCityPersonTemplate);
    const [service, setService] = useState<ContactZipPersonInterface>(contactZipPersonTemplate);
    const [isPersonBoxZipVisible, setIsPersonBoxZipVisible] = useState(false);
    const [isPersonBoxCityVisible, setIsPersonBoxCityVisible] = useState(false);

    useEffect(() => {
        if (Object.keys(contactPersonData)?.length > 0) {
            dataToContactPerson.length = 0;
            contactPersonData?.contacts
                ?.filter((contact) => contact.type !== 'service')
                ?.forEach((contact) => {
                    setDataToContactPerson((dataToContactPerson) => [
                        ...dataToContactPerson,
                        convertDataToContactPersonComponent(contact),
                    ]);
                });
        }
    }, [contactPersonData]);

    const convertDataToContactPersonComponent = (data: any): ContactPersonInterface => {
        const contactPersonData = {
            attachments: {
                admPhoto0: data.attachments.Photo0,
            },
            Name: data.Name,
            Email: data.Mail,
            Fon: data.Tel,
        };
        return contactPersonData;
    };

    return (
        <>
            <Container>
                <GermanTitle>{t('contact')}</GermanTitle>
                <WebForm
                    language={DE}
                    webformid={CONTACT_DE}
                    confirmationTitle={CONTACT_DE_TITLE}
                    style={{ backgroundColor: greyBackground, padding: 25 }}
                />
                <GermanSubtitle>{t('contactPartner')}</GermanSubtitle>
                <Description>
                    {t('contactDescription')} <a href="#site-bottom">Kontaktformular.</a>
                </Description>
                <SearchFormsContainer>
                    <ContactSearchByZip
                        zip={zip}
                        setZip={setZip}
                        previousZip={previousZip}
                        isPersonBoxZipVisible={isPersonBoxZipVisible}
                        setService={setService}
                        setContactPersonData={setContactPersonData}
                        setIsPersonBoxZipVisible={setIsPersonBoxZipVisible}
                        setIsPersonBoxCityVisible={setIsPersonBoxCityVisible}
                        setPreviousZip={setPreviousZip}
                    />
                    <ContactSearchByCity
                        city={city}
                        setCity={setCity}
                        setIsPersonBoxCityVisible={setIsPersonBoxCityVisible}
                        setIsPersonBoxZipVisible={setIsPersonBoxZipVisible}
                        setDataToContactPersonByCity={setDataToContactPersonByCity}
                    />
                </SearchFormsContainer>
            </Container>
            {isPersonBoxZipVisible && (
                <PersonByZip
                    isPersonBoxZipVisible={isPersonBoxZipVisible}
                    setIsPersonBoxZipVisible={setIsPersonBoxZipVisible}
                    contactPersonData={contactPersonData}
                    dataToContactPerson={dataToContactPerson}
                    service={service}
                />
            )}
            {isPersonBoxCityVisible && (
                <PersonByCity
                    isPersonBoxCityVisible={isPersonBoxCityVisible}
                    setIsPersonBoxCityVisible={setIsPersonBoxCityVisible}
                    dataToContactPersonByCity={dataToContactPersonByCity}
                />
            )}
            <Container>
                <GermanSubtitle>Unsere Druckluftexperten auf einen Blick</GermanSubtitle>
                <Description>
                    Auf Wunsch beraten wir Sie direkt vor Ort. Sprechen Sie uns gern an, falls Sie Fragen rund um das
                    Thema Druckluft haben oder auf der Suche nach einem Kompressor sind. BOGE Druckluftexperten in Ihrer
                    Nähe finden Sie schnell und einfach über unsere Karte.
                </Description>
                <LinkWrapper>
                    <ButtonWithLinkGreyGradient to="/de/deutschland-boge-kontakt">
                        Expertenverzeichnis
                    </ButtonWithLinkGreyGradient>
                </LinkWrapper>
                <GermanSubtitle>BOGE weltweit</GermanSubtitle>
                <Description>
                    Um Kontakt zu einem unserer weltweiten Ansprechpartner aufzunehmen, verwenden Sie bitte unsere
                    internationale Kontaktsuche.
                </Description>
                <LinkWrapper>
                    <ButtonWithLinkGreyGradient to="/en/contact">
                        Zur internationalen Kontaktsuche
                    </ButtonWithLinkGreyGradient>
                </LinkWrapper>
            </Container>
            <ContactData />
        </>
    );
};

export default ContactGerman;
