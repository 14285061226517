import axios from 'axios';

export const useFetchRegionDetails = async (region: string): Promise<any> => {
    const data = await axios.post(
        `${process.env.DRUPAL_URL}/graphql`,
        {
            query: `query fetchRegionDetails($region: String!) {
                        region_details(region: $region) {
                            data
                        }
                    }
            `,
            variables: {
                region: region,
            },
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
    const result = JSON.parse(data?.data?.data?.region_details?.data);
    return result ?? {};
};
